import React from 'react';

import Title from '../Cards/title';
import Footer from '../Footer/footer';
import Header from '../components/Navigation/header';

import ScrollToTop from '../components/scrollToTop/ScrollToTop';

const LiveCoaching = (props) => {
	return (
		<React.Fragment>
			<ScrollToTop />
			<Header />
			<main>
				<Title title={props.title && props.title} extend={props.extend} />
				{props.children}
			</main>
			<Footer />
		</React.Fragment>
	);
};

export default LiveCoaching;
