import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

const app = initializeApp({
	type: 'service_account',
	projectId: 'ludmila-reiner',
	clientEmail: 'firebase-adminsdk-uivvz@ludmila-reiner.iam.gserviceaccount.com',
	clientId: '107265646844092810452',
	apiKey: 'AIzaSyAKKPO5F4QjKRtN-wkMq6eo6RJqDQQxQ6A',
	authDomain: 'ludmila-reiner.firebaseapp.com',
	storageBucket: 'ludmila-reiner.appspot.com',
	messagingSenderId: '1092300098490',
	appId: '1:1092300098490:web:2ebf0ed2f35cd4c6af2b83',
	measurementId: 'G-C2BD6WCF9W',
});

const db = getFirestore(app);

console.log(db);
const analytics = getAnalytics(app);

ReactDOM.render(
	<React.StrictMode>
		<App database={db} />
	</React.StrictMode>,
	document.getElementById('root')
);

reportWebVitals();
