import React, { Suspense } from 'react';
import HttpsRedirect from 'react-https-redirect';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import BeginningPage from './Beginning/pages/beginning';

import LoadingSpinner from './shared/components/UI/LoadingSpinner';

import './index.css';

const AboutMePage = React.lazy(() => import('./AboutMe/pages/aboutMe'));
const LifeCoachingPage = React.lazy(() => import('./LifeCoaching/pages/lifeCoaching'));
const ExcecutiveCoaching = React.lazy(() =>
	import('./ExcecutiveCoaching/pages/excecutiveCoaching')
);
const ContactPage = React.lazy(() => import('./Contact/pages/contact'));

const App = (props) => {
	return (
		<HttpsRedirect>
			<Suspense
				fallback={
					<div className="fallback">
						<LoadingSpinner />
					</div>
				}
			>
				<Router>
					<Switch>
						<Route path="/aboutMe" component={AboutMePage} />
						<Route path="/lifeCoaching" component={LifeCoachingPage} />
						<Route
							path="/excecutiveCoaching"
							component={ExcecutiveCoaching}
						/>
						<Route
							path="/contact"
							render={() => <ContactPage {...props} />}
						/>
						<Route
							path="/"
							exact
							render={() => <BeginningPage {...props} />}
						/>
						<Redirect to="/" />
					</Switch>
				</Router>
			</Suspense>
		</HttpsRedirect>
	);
};

export default App;
