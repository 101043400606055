import React from 'react';

import { Link } from 'react-router-dom';

import LicenseImage from '../../assets/images/asesco.jpg';
import EmailIcon from '../../assets/icons/email.svg';
import FacebookIcon from '../../assets/icons/Facebook.png';
import TwitterIcon from '../../assets/icons/Twitter.png';
import InstagramIcon from '../../assets/icons/Instagram.png';
import LinkedInIcon from '../../assets/icons/LinkedIn.png';
import WhatsappIcon from '../../assets/icons/whatsapp.svg';

import './footer.css';

const Footer = () => {
	return (
		<footer className="footer-container">
			<div className="footer-info">
				<div className="footer-links">
					<p>
						<b>Enlaces de interés</b>
					</p>
					<a href="https://ludmilareiner.blogspot.com/">Blog</a>
					<Link to="./contact">Contacto</Link>
					<div className="footer-contact__licenseImage">
						<img src={LicenseImage} alt="license" />
					</div>
				</div>
				<div className="footer-contact">
					<div className="footer-contact__title">
						<p>
							<b>Contacto Directo</b>
						</p>
					</div>
					<div className="footer-contact__info">
						<div className="footer-contact__info-detail">
							<img src={WhatsappIcon} alt="phone-icon" />
							<p>+34 698 46 26 74</p>
						</div>
						<div className="footer-contact__info-detail">
							<img src={EmailIcon} alt="email-icon" />
							<p>contacto@ludmilareiner.com</p>
						</div>
					</div>
				</div>
				<div className="footer-networks">
					<div className="footer-networks__title">
						<p>
							<b>Sígueme</b>
						</p>
					</div>
					<div className="footer-networks__links">
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.facebook.com/LudmilaReinerCoach"
						>
							<img src={FacebookIcon} alt="Facebook-Icon" />
						</a>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://twitter.com/LudmilaReiner"
						>
							<img src={TwitterIcon} alt="Twitter-Icon" />
						</a>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.instagram.com/ludmilareiner/"
						>
							<img src={InstagramIcon} alt="Instagram-Icon" />
						</a>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.linkedin.com/in/ludmila-reiner-coach-profesional-y-gesti%C3%B3n-de-talento-humano-aa26913a/"
						>
							<img src={LinkedInIcon} alt="LinkedIn-Icon" />
						</a>
					</div>
				</div>
			</div>
			<div className="footer-property">
				<p>2021 Copyright @ Ludmila Reiner | Todos los Derechos Reservados</p>
			</div>
		</footer>
	);
};

export default Footer;
