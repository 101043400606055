import React from 'react';

import { useForm } from '../../shared/hooks/form-hook';
import { VALIDATOR_REQUIRE, VALIDATOR_EMAIL } from '../../utils/validators';

import Input from '../../shared/components/FormElements/input';
import Button from '../../shared/components/FormElements/button';

import { doc, setDoc } from 'firebase/firestore';

import './tips.css';

const Tips = (props) => {
	const inputs = {
		newsletter: {
			value: '',
			isValid: false,
		},
	};
	const [formState, inputHandler] = useForm(inputs, false);

	const submitHandler = async (event) => {
		event.preventDefault();
		const correo = formState.inputs.newsletter.value;
		try {
			await setDoc(doc(props.database, 'newsletter', correo), { email: correo });
			alert('Su correo ha sido inscrito correctamente.');
		} catch (err) {
			console.log(err);
			alert('No se pudo guardar tu correo. Por favor, intenta luego.');
		}
	};

	return (
		<div className="beggining-page__tips">
			<div className="tips-tittle">
				<h2>¿Quieres recibir tips sobre gestión emocional?</h2>
			</div>
			<div className="tips-body">
				<p>
					Inscríbete en el <span>newsletter</span> para estar al tanto de los
					programas y todas las actividades gratuitas que realizamos
				</p>
				<div className="tips-container__form">
					<form onSubmit={submitHandler}>
						<Input
							id="newsletter"
							placeholder="Escriba su correo aquí"
							type="text"
							onInput={inputHandler}
							validators={[VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]}
						/>
						<Button type="submit" disabled={!formState.isValid} size="100px">
							Enviar
						</Button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Tips;
