import React from 'react';

import { useForm } from '../../shared/hooks/form-hook';
import { VALIDATOR_REQUIRE } from '../../utils/validators';

import Input from '../../shared/components/FormElements/input';
import Button from '../../shared/components/FormElements/button';

import WhiteLogo from '../../assets/images/whiteLogo.jpg';

import './contactForm.css';
import { doc, setDoc } from '@firebase/firestore';

const ContactForm = (props) => {
	const inputs = {
		nombre: {
			value: '',
			isValid: false,
		},
		correo: {
			value: '',
			isValid: false,
		},
		telefono: {
			value: '',
			isValid: false,
		},
		mensaje: {
			value: '',
			isValid: false,
		},
	};
	const [formState, inputHandler] = useForm(inputs, false);

	const submitHandler = async (event) => {
		event.preventDefault();
		const correo = formState.inputs.correo.value;
		const docData = {
			nombre: formState.inputs.nombre.value,
			correo: correo,
			telefono: formState.inputs.telefono.value,
			mensaje: formState.inputs.mensaje.value,
		};

		try {
			await setDoc(doc(props.database, 'message', correo), docData);
			alert('Su mensaje fue recibido exitosamente');
		} catch (err) {
			alert('No se pudo enviar su mensaje. Por favor, intente luego.');
		}
	};

	return (
		<div className="contact-form__container">
			<form onSubmit={submitHandler}>
				<div className="form-body">
					<h2>Contacto</h2>
					<Input
						id="nombre"
						label="Nombre"
						placeholder="Escriba su nombre y apellido aquí"
						type="text"
						onInput={inputHandler}
						validators={[VALIDATOR_REQUIRE()]}
					/>
					<Input
						id="correo"
						label="Email"
						placeholder="Escriba su correo aquí"
						type="text"
						onInput={inputHandler}
						validators={[VALIDATOR_REQUIRE()]}
					/>
					<Input
						id="telefono"
						label="Teléfono"
						placeholder="Escriba su teléfono aquí"
						type="text"
						onInput={inputHandler}
						validators={[VALIDATOR_REQUIRE()]}
					/>
					<Input
						id="mensaje"
						label="Mensaje"
						placeholder="Escriba su mensaje aquí"
						type="textarea"
						onInput={inputHandler}
						validators={[VALIDATOR_REQUIRE()]}
					/>
					<Button type="submit" disabled={!formState.isValid} size="100%">
						Enviar
					</Button>
				</div>
			</form>
			<div className="contact-form__images">
				<img src={WhiteLogo} alt="" />
			</div>
		</div>
	);
};

export default ContactForm;
