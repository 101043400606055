import React from 'react';

import Button from '../../shared/components/FormElements/button';

import LiveCoachingImage from '../../assets/images/liveCoachingHome.jpg';
import ExecutiveCoachingImage from '../../assets/images/excecutiveCoachingHome.jpg';

import './coachings.css'

const Coachings=()=>{
    return(
        <div className="beginning-page__coaching">
                <div className="life-coaching__container">
                    <div className="life-coaching__title">
                        <h2>Live Coaching</h2>
                        <p>Live Coaching es un proceso de desarrollo personal en el que el
                            coach te ayuda a desarrollar tus recursos, a lograr cambios en tu
                            vida, a definir lo que quieres, a superar obstáculos, a superarte y
                            a conseguir una vida más satisfactoria.</p>
                        <Button to="/lifeCoaching">
                            Leer más
                        </Button>
                    </div>
                    <img src={LiveCoachingImage} alt="" />
                </div>
                <div className="excecutive-coaching__container">
                    <img src={ExecutiveCoachingImage} alt="" />
                    <div className="excecutive-coaching__title">
                        <h2>Executive Coaching</h2>
                        <p>Es un proceso de interaccción con acompañamiento expansivo.
                            Sesiones individuales de coaching empresarial. Sesiones de coaching
                            de equipos donde se desarrollan herramientas para poder aumentar
                            la eficiencia de tus empleados generando un ambiente de trabajo más
                            ameno para todos.</p>
                        <Button to="/excecutiveCoaching">
                            Leer más
                        </Button>
                    </div>
                </div>
            </div>
    );
}

export default Coachings;