import React from 'react';

import './title.css'

const Title = (props) => {

    let element;
    if (props.extend) {
        element = (
            <div className="title-container">
                <hr className="division-line" />
                <div className="title-text___extend">
                    <div className="vertical-line__extend" />
                    <div className="extend-info">
                        <h1>El Movimiento con <br />inteligencia es la acción</h1>
                        <h2>Transforma tu vida</h2>
                        <p>"Diseño programas de coaching y PNL interactivos que te llevarán de
                            la mano para desarrollar tu inteligencia emocional o mejorar el
                            ambiente socio emocional de tu empresa"</p>
                    </div>
                </div>
                <hr className="division-line" />
            </div>
        );
    } else {
        element = (
            <div className="title-container">
                <hr className="division-line" />
                <div className="title-text">
                    <div className="vertical-line__simple" />
                    <h1>{props.title}</h1>
                </div>
                <hr className="division-line" />
            </div>
        );
    }

    return (
        <React.Fragment>
            {element}
        </React.Fragment>
    );
}

export default Title;