import React from 'react';

import Button from '../../shared/components/FormElements/button';

import SimpleLogo from '../../assets/images/simpleLogo.png';
import LudmilaImgHome from '../../assets/images/ludmilaHome.jpg';

import './invitation.css';

const Invitation = () => {
	return (
		<div className="beggining-page__programs">
			<div className="programs-header">
				<div className="programs-header__description">
					<img src={SimpleLogo} alt="" />
					<div className="programs-header__description-text">
						<p>
							<span>Todos mis programas</span> están diseñados para
							permitirte
							<span> superar cualquier bloqueo emocional</span> llevándote a
							ser una
							<span> persona armoniosa, plena, feliz y exitosa.</span>
						</p>
					</div>
				</div>

				<div className="programs-header__invitation">
					<div className="vertical-line__invitation" />
					<div className="programs-header__invitation-detail">
						<h2>¿Te atreves a transformar tu vida?</h2>
						<p>
							Mis programas personalizados te enseñan a “alcanzar tus metas”
						</p>
						<Button to="/lifeCoaching">Ver Programa</Button>
					</div>
				</div>
			</div>
			<div className="programs-body">
				<div className="programs-body__text">
					<div className="vertical-line__programs-body" />
					<p>
						Todos en un momento determinado de nuestras vidas hemos explotado,
						nos hemos congelado, nos ha faltado el aire o las palabras ante un
						suceso, hemos sentido que algo superior a nosotros mismos controla
						nuestras reacciones, cuando esto pasa sentimos culpa,
						arrepentimiento, rabia, deseos de volver atrás…
						<br />
						<br />
						¿Quieres hacer las paces con ese ser que está controlando tu vida?
						Llegar a un acuerdo… ¿Quieres aprender a gestionar tus emociones?
						<br />
						<br />
						¿Te imaginas teniendo la capacidad de poner límites, de expresar
						tus emociones e ideas de forma asertiva?, elegir como vivir esas
						emociones, darte el tiempo y el espacio necesario, conseguir tus
						objetivos.
					</p>
				</div>
				<div className="programs-body__img">
					<div className="programs-body__img-content">
						<img src={LudmilaImgHome} alt="" />
					</div>
					<div className="programs-body__img-footer">
						<p>Yo te enseño como hacerlo…</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Invitation;
