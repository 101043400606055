import React from 'react';

import MainLayout from '../../shared/layout/MainLayout';
import CoachingCards from '../components/coachings';
import InvitationCard from '../components/invitation';
// import ReviewsCard from '../components/reviews';
import TipsCard from '../components/tips';
import ContactForm from '../../Contact/components/contactForm';

const Beginning = (props) => {
	return (
		<MainLayout extend>
			<CoachingCards />
			<InvitationCard />
			{/* <ReviewsCard /> */}
			<TipsCard {...props} />
			<ContactForm {...props} />
		</MainLayout>
	);
};

export default Beginning;
