import React from 'react';
import { Link } from 'react-router-dom';

import CalendarIcon from '../../../assets/icons/calendar.svg';
import './button.css';

const Button = (props) => {
	if (props.to) {
		return (
			<Link
				to={props.to}
				exact={props.exact}
				className="button"
				style={{ width: props.size }}
			>
				{props.children}
			</Link>
		);
	} else if (props.calendar) {
		return (
			<Link to="/contact" className="button" style={{ width: props.size }}>
				<div className="request__proof">
					<img src={CalendarIcon} alt="" />
					{props.children}
				</div>
			</Link>
		);
	}

	return (
		<button
			type={props.type}
			className={`button ${props.disabled && 'button--disabled'}`}
			style={{ width: props.size }}
			disabled={props.disabled}
			onClick={props.onClick}
		>
			{props.children}
		</button>
	);
};

export default Button;
