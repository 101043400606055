import React from 'react';

import { NavLink } from 'react-router-dom';

import './NavLinks.css';

const NavLinks = () => {
	return (
		<nav className="header-nav__links">
			<ul className="nav__links">
				<li>
					<div className="vertical-line__header" />
					<NavLink to="/" exact>
						Inicio
					</NavLink>
				</li>
				<li>
					<div className="vertical-line__header" />
					<NavLink to="/aboutMe">Sobre mí</NavLink>
				</li>
				<li>
					<div className="vertical-line__header" />
					<NavLink to="/lifeCoaching">Life Coaching</NavLink>
				</li>
				<li>
					<div className="vertical-line__header" />
					<NavLink to="/excecutiveCoaching">Executive Coaching</NavLink>
				</li>
				<li>
					<div className="vertical-line__header" />
					<NavLink to="/contact">Contacto</NavLink>
				</li>
			</ul>
		</nav>
	);
};

export default NavLinks;
