import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import BackDrop from '../UI/Backdrop';
import SideDrawer from './SideDrawer';

import './header.css';

import CompleteLogo from '../../../assets/images/completeLogo.png';
import NavLinks from './NavLinks';

const Header = () => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const closeDrawerHandler = () => {
		setIsDrawerOpen(false);
	};
	const openDrawerHandler = () => {
		setIsDrawerOpen(true);
	};

	return (
		<React.Fragment>
			{isDrawerOpen && <BackDrop onClick={closeDrawerHandler} />}
			<SideDrawer show={isDrawerOpen} onClick={closeDrawerHandler}>
				<header>
					<NavLinks />
				</header>
			</SideDrawer>
			<header className="header-container">
				<div className="header-logo">
					<Link to="./">
						<img src={CompleteLogo} alt="logo" />
					</Link>
				</div>
				<button className="header-toggle" onClick={openDrawerHandler}>
					<span />
					<span />
					<span />
				</button>
				<div className="header__nav-desktop">
					<NavLinks />
				</div>
			</header>
		</React.Fragment>
	);
};

export default Header;
