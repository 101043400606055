import React, { useReducer, useEffect } from 'react';

import { validate } from '../../../utils/validators';

import './input.css'

const inputReducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE':
            return {
                ...state,
                value: action.val,
                isValid: validate(action.val, action.validators)
            };
        case 'TOUCH':
            return {
                ...state,
                isTouched: true
            }
        default:
            return state;
    }
}

const Input = props => {

    const [inputState, dispatch] = useReducer(inputReducer, { value: '', isTouched: false, isValid: false });

    const { id, onInput } = props;
    const { value, isValid } = inputState;

    useEffect(() => {
        if (props.type !== 'radio') {
            onInput(id, value, isValid)
        }
    }, [id, onInput, value, isValid, props.type])


    const changeHandler = event => {
        dispatch({ type: 'CHANGE', val: event.target.value, validators: props.validators });
    }

    const touchHandler = () => {
        dispatch({
            type: 'TOUCH'
        })
    }


    let element;
    if (props.type === 'text') {
        element = (
            <input
                id={id}
                type={props.type}
                onChange={changeHandler}
                onBlur={touchHandler}
                placeholder={props.placeholder}
                value={value} />
        )
    } else if (props.type === 'textarea') {
        element = (
            <textarea
                id={id}
                rows={props.rows || 3}
                onChange={changeHandler}
                onBlur={touchHandler}
                placeholder={props.placeholder}
                value={value} />
        )
    } 

    return (
        <div className={`form-control ${!inputState.isValid && inputState.isTouched && 'form-control--invalid'}`}>
            <label htmlFor={props.id}>{props.label}</label>
            {element}
            {!inputState.isValid && inputState.isTouched && <p>{props.errorText}</p>}
        </div>
    )
}

export default Input;