import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { CircularProgress } from '@material-ui/core';

import './LoadingSpinner.css';

const useStylesFacebook = makeStyles(() => ({
	color: {
		color: '#761418',
	},
}));

const LoadingSpinner = () => {
	const classes = useStylesFacebook();
	return (
		<div>
			<CircularProgress
				variant="indeterminate"
				color="primary"
				className={classes.color}
			/>
		</div>
	);
};
export default LoadingSpinner;
